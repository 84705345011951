import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home')
    },
    {
        path: '/bt',
        name: 'Banktransfer',
        component: () => import('../views/banktransfer/index')
    },
    {
        path: '/qr30/gbpay',
        name: 'QrPayGbpay',
        component: () => import('../views/gbpay/qrpay')
    },
    {
        path: '/qr30/ghl',
        name: 'QrPayGhl',
        component: () => import('../views/ghlpayment/qrpay')
    },
    {
        path: '/mc/loading',
        name: 'McpaymentLoading',
        component: () => import('../views/mcpayment/loading')
    },
    {
        path: '/mc/return',
        name: 'McpaymentReturm',
        component: () => import('../views/mcpayment/return')
    },
    {
        path: '/crypto',
        name: 'Crypto',
        component: () => import('../views/crypto/index')
    },
    {
        path: '/crypto/demo',
        name: 'CryptoDemo',
        component: () => import('../views/crypto/demo')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
